import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`あくまで、僕が「個人的にStudio Oneを強く勧めている」というだけですので、お気に入りのDAWがある場合は、`}<strong parentName="p">{`いきなり無理に買うべきではないと思います。`}</strong>{`（プラグインでもそうですが、道具はポンポン買うべきではありません）`}</p>
      <p>{`ただ、僕はStudio Oneの、洗練された無駄のないワークフローに惚れ込んでいますし、見本曲でも全トラックに使用している付属プラグインの「Channnel Strip」は「シンプルなのに超使える」という便利なものなので、少なくともこの教材を実践するときには、`}<strong parentName="p">{`一度３０日の体験版を使ってみることをお勧めします。`}</strong><br parentName="p"></br>{`
`}{`動画でも言っていますが、僕はこのDAWに変えてから、MIXの手法が大きく変わりましたので。`}</p>
      <p>{`その際、Studio Oneには無料版の「`}<strong parentName="p">{`Prime`}</strong>{`」と、フル版の「`}<strong parentName="p">{`Professional`}</strong>{`」、そして中間の「`}<strong parentName="p">{`Artist`}</strong>{`」という３つのバージョンがあります。見本曲では、「Artist」以上にしか付属していないプラグインも使用していますので（Fat channelとLimitterとProEQ）、無料版の「Prime」で同じことを完全に再現するのは無理だと思います。ただし、全トラックで使用している「Channel Strip」は、無料版の「Prime」でも使えるので、大半のことは再現できると思います。`}</p>
      <p>{`ですので、`}<strong parentName="p">{`まずは無料でPrimeを使ってみて、さらに必要が出てきた段階で「Professional」の３０日体験版を使ってみる`}</strong>{`というのでもいいと思います（再インストールなどは必要なく、単に「体験版を使う」的なボタンを押すだけで、機能が解放されたと思います）。`}</p>
      <p>{`後は、もし使ってみて気に入ったら、「Artist」か「Professional」の好きな方を買えば良いと思います。`}</p>
      <p>{`繰り返しになりますが、僕が勧めたからと言って「その場で無思考にProfessionalを買ってしまった」というようなことは避けてください。僕が常に言っているのは、「`}<strong parentName="p">{`本当に必要なものにはお金を使い、本当に必要でないものにはお金を使わない`}</strong>{`」ということです。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      