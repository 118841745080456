import React from "react"
import { Link } from "gatsby"
import SEO from '../../../components/seo'

import Elements from '../../../layouts/elements'

import Hosoku from '@components/md-components/hosoku/0-3.md'



const youso=	0
const part=		3
const title=	'StudioOneを使う理由'
const url=		'318939294'

const iti=		youso + '-' +part
const h1=		'【' + iti + '】' + title
const mae= 		'/elements/'+youso+'/'+youso +'-'+(part-1)+'/'
const ato= 		'/elements/'+youso+'/'+youso +'-'+(part+1)+'/'


export default () => (


<Elements
	mae={mae}
	ato={ato}
	url={url}
	h1={h1}
>

	<SEO title={title} />

	<Hosoku/>

</Elements>
)